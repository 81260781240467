<template>
  <div class="company-auth">
    <PageHeader is-router title="企业认证"></PageHeader>
    <el-form
      class="auth-form"
      :model="form"
      :rules="formRules"
      :hide-required-asterisk="true"
      size="small"
      ref="formRef"
      label-position="right"
      label-width="290px"
    >
      <section class="form-section">
        <div class="form-section-title">企业信息</div>
        <el-form-item prop="licenseId">
          <template #label>
            <form-label required label="营业执照"></form-label>
          </template>
          <div class="license">
            <!-- <div class="license-img">
              <img @click="uploadImg(UPLOAD_TYPE.LICENSE)" :src="form.licenseImg" class="s-img" />
            </div> -->
            <el-upload
              class="avatar-uploader"
              action="/file/upload"
              accept="*.bmp,*.jpg,*.png"
              :show-file-list="false"
              :before-upload="beforeUploader"
              :on-success="(res, file, fileList) => onSuccess(res, UPLOAD_TYPE.LICENSE, file, fileList)"
            >
              <img v-if="form.licenseImg" :src="form.licenseImg" class="s-img" />
              <img v-else :src="licenseImg" class="s-img" />
            </el-upload>
            <div class="upload-preview-tip">
              上传营业执照图片
              <a @click="lookExample(UPLOAD_TYPE.LICENSE)">查看示图</a>
            </div>
            <div class="warning-text">
              <label>注意：</label>
              <div class="text">
                1、请保证营业执照各项信息清晰可见，无遮挡。
                <br />
                2、上传图片文件格式支持png，jpg和bmp。
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="creditCode">
          <template #label>
            <form-label required label="社会信用代码"></form-label>
          </template>
          <div class="inline-block">
            <el-input v-model="form.creditCode"></el-input>
            <div class="tips">请输入18位的统一社会信用代码</div>
          </div>
        </el-form-item>
        <el-form-item prop="companyName">
          <template #label>
            <form-label required label="企业全称"></form-label>
          </template>
          <div class="inline-block">
            <el-input v-model="form.companyName"></el-input>
            <div class="tips">需与营业执照的名称完全一致</div>
          </div>
        </el-form-item>
        <el-form-item prop="shortName">
          <template #label>
            <form-label required label="企业简称"></form-label>
          </template>
          <div class="inline-block">
            <el-input v-model="form.shortName"></el-input>
            <div class="tips">将展示在企业成员的名片上</div>
          </div>
        </el-form-item>
      </section>
      <section class="form-section">
        <div class="form-section-title">法人信息</div>
        <el-form-item prop="idCard">
          <template #label>
            <form-label required label="上传法人身份证照片"></form-label>
          </template>
          <div class="id-car-wrap">
            <div class="id-card-item">
              <!-- <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
              ></el-upload> -->
              <!-- <div class="img-wrap" @click="uploadImg(UPLOAD_TYPE.IDCARD)">
                <img :src="form.idCardImg" class="s-img" />
              </div> -->
              <el-upload
                class="avatar-uploader"
                action="/file/upload"
                accept="*.bmp,*.jpg,*.png"
                :show-file-list="false"
                :before-upload="beforeUploader"
                :on-success="(res, file, fileList) => onSuccess(res, UPLOAD_TYPE.IDCARD, file, fileList)"
              >
                <img v-if="form.idCardImg" :src="form.idCardImg" class="s-img" />
                <img v-else :src="idCardImg" class="s-img" />
              </el-upload>
              <div class="upload-preview-tip">
                上传身份证正面
                <a @click="lookExample(UPLOAD_TYPE.IDCARD)">查看示图</a>
              </div>
            </div>
            <div class="id-card-item">
              <el-upload
                class="avatar-uploader"
                action="/file/upload"
                accept="*.bmp,*.jpg,*.png"
                :show-file-list="false"
                :before-upload="beforeUploader"
                :on-success="(res, file, fileList) => onSuccess(res, UPLOAD_TYPE.IDCARD_BACK, file, fileList)"
              >
                <img v-if="form.idCardBackImg" :src="form.idCardBackImg" class="s-img" />
                <img v-else :src="idCardBackImg" class="s-img" />
              </el-upload>
              <!-- <div class="img-wrap" @click="uploadImg(UPLOAD_TYPE.IDCARD_BACK)">
                <img :src="form.idCardBackImg" class="s-img" />
              </div> -->
              <div class="upload-preview-tip">
                上传身份证反面
                <a @click="lookExample(UPLOAD_TYPE.IDCARD_BACK)">查看示图</a>
              </div>
            </div>
          </div>
          <div class="warning-text">
            <label>注意：</label>
            <div class="text">
              1、请保证身份证各项信息清晰可见，无遮挡。
              <br />
              2、上传图片文件格式支持png，jpg和bmp。
            </div>
          </div>
        </el-form-item>
        <el-form-item prop="legalName">
          <template #label>
            <form-label required label="法定代表人身份证姓名"></form-label>
          </template>
          <div class="inline-block">
            <el-input v-model="form.legalName"></el-input>
            <div class="tips">请填写与营业执照一致的法定代表人姓名</div>
          </div>
        </el-form-item>
        <el-form-item prop="legalIdCardNumber">
          <template #label>
            <form-label required label="法定代表人身份证号"></form-label>
          </template>
          <div class="inline-block">
            <el-input v-model="form.legalIdCardNumber"></el-input>
            <div class="tips">请填写法定代表人身份证号码</div>
          </div>
        </el-form-item>
      </section>
      <div class="footer-btns">
        <el-button type="primary" size="small" @click="onSubmit">提交</el-button>
      </div>
    </el-form>
    <div v-if="imgVisible" class="imgExample">
      <div>
        <div class="exampleImg">
          <div class="icons-close" @click="closeExample"><i class="iconfont icon-a-"></i></div>
          <img :src="EXAMPLE[exampleSelected]" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { nextTick, reactive, ref, toRefs } from 'vue'
import PageHeader from '@/views/components/page-header/index.vue'
import FormLabel from '@/views/components/form-label'
import { IDCardNumberValidator, creditCodeValidator } from '@/common/validator'
import {
  LICENSE_UPLOAD_HOLDER,
  ID_CARD_UPLOAD_HOLDER,
  ID_CARD_BACK_UPLOAD_HOLDER,
  ID_CARD_FRONT,
  ID_CARD_NEGATIVE,
  BUSINESS_LICENSE
} from '@/common/const'
import { limitFileSize } from '@/common/utils'
import { ElMessage } from 'element-plus'
const UPLOAD_TYPE = {
  LICENSE: 1,
  IDCARD: 2,
  IDCARD_BACK: 3
}
const EXAMPLE = {
  1: BUSINESS_LICENSE,
  2: ID_CARD_FRONT,
  3: ID_CARD_NEGATIVE
}
export default {
  name: 'companyAuth',
  components: { FormLabel, PageHeader },
  setup() {
    const formRef = ref(null)
    const state = reactive({
      form: {
        licenseId: '',
        licenseImg: '',
        idCard: '',
        idCardImg: '',
        idCardBack: '',
        idCardBackImg: '',
        creditCode: '', // 企业信用代码
        companyName: '', // 企业名称
        shortName: '', // 企业简称
        legalName: '', // 法人姓名
        legalIdCardNumber: '' // 法人身份证号码
      },
      licenseImg: LICENSE_UPLOAD_HOLDER,
      idCardImg: ID_CARD_UPLOAD_HOLDER,
      idCardBackImg: ID_CARD_BACK_UPLOAD_HOLDER,
      currentUploadType: null,
      exampleSelected: null,
      imgVisible: false
    })
    // 验证身份证图片上传
    const validateIdCardImg = (rule, value, callback) => {
      if (!state.form.idCard) {
        callback('请上传法人身份证正面')
      } else if (!state.form.idCardBack) {
        callback('请上传法人身份证反面')
      } else {
        callback()
      }
    }
    const formRules = {
      licenseId: [{ required: true, message: '请上传营业执照' }],
      idCard: [{ validator: validateIdCardImg, trigger: 'change' }],
      creditCode: [
        { required: true, message: '请输入企业信用代码', trigger: ['blur', 'change'] },
        {
          validator: creditCodeValidator,
          trigger: ['blur', 'change']
        }
      ],
      companyName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
      shortName: [{ required: true, message: '请输入企业简称', trigger: ['blur', 'change'] }],
      legalName: [{ required: true, message: '请输入法人姓名', trigger: ['blur', 'change'] }],
      legalIdCardNumber: [
        { required: true, message: '请输入法人身份证号码', trigger: ['blur', 'change'] },
        {
          validator: IDCardNumberValidator,
          trigger: 'change'
        }
      ]
    }
    const validateField = file => {
      formRef.value.validateField(file)
    }
    const onSubmit = () => {
      formRef.value.validate(valid => {
        console.log('va', state.form)
        if (!valid) return false
      })
    }

    const uploadRef = ref(null)
    const uploadImg = type => {
      nextTick(() => {
        state.currentUploadType = type
        // uploadRef.value.$slots.default()[0].el.click()
      })
    }
    const beforeUploader = file => {
      // 判断是否为excel文件
      const name = file.name
      const extName = name.substring(name.lastIndexOf('.'), name.length)
      // .rar .zip .doc .docx .pdf
      console.log(extName)
      if (!['.bmp', '.jpg', '.png'].includes(extName)) {
        ElMessage.error('仅支持上传.jpg,.png,.bmp格式的图片')
        return false
      }
      // 判断是否超过限制
      if (!limitFileSize(file, '20MB')) {
        ElMessage.error(`文件大小超出限制: 20MB`)
        return false
      }
      console.log('file', file)
    }
    const onSuccess = (res, type) => {
      switch (type) {
        case UPLOAD_TYPE.LICENSE:
          state.form.licenseId = res.data.id
          state.form.licenseImg = window.origin + res.data.path
          // 因为没有v-model 需要单独触发验证
          validateField('licenseId')
          break
        case UPLOAD_TYPE.IDCARD:
          state.form.idCard = res.data.id
          state.form.idCardImg = window.origin + res.data.path
          // 因为没有v-model 需要单独触发验证
          validateField('idCard')
          break
        case UPLOAD_TYPE.IDCARD_BACK:
          state.form.idCardBack = res.data.id
          state.form.idCardBackImg = window.origin + res.data.path
          // 因为没有v-model 需要单独触发验证
          validateField('idCard')
          break
        default:
          break
      }
      // this.ruleForm.logo_url = 'http://dev.khtong.cn' + res.data.path
      // this.ruleForm.logo_url = window.origin + res.data.path
    }
    const lookExample = type => {
      console.log('1231231232')
      state.exampleSelected = type
      state.imgVisible = true
    }
    const closeExample = () => {
      state.imgVisible = false
      state.exampleSelected = null
    }
    return {
      ...toRefs(state),
      formRef,
      onSubmit,
      UPLOAD_TYPE,
      EXAMPLE,
      uploadRef,
      uploadImg,
      formRules,
      beforeUploader,
      onSuccess,
      lookExample,
      closeExample
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
